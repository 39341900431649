import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Label,
  Input, Form
} from 'reactstrap';

const CreateAddressModal = (props) => {
  const {
    className,
    type,
    selectId
  } = props;
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [responseError, setResponseError] = useState('');
  const [formData, setFormData] = useState({
    company: '',
    street: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    address_type: type
  });

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "can't be blank";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      const response = await fetch('/addresses', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ address: formData })
      });
      const result = await response.json();
      console.log('Response:', result);
      if (response.status === 422) {
        setResponseError(result.street[0]);
        return;
      }
      addNewOptionToSelector(selectId, result.id, formData.company, formData.street, formData.city, formData.state, formData.zip_code);
      selectOption(selectId, result.id);
      toggle();
      // Handle the response as needed
    } catch (error) {
      setResponseError
      console.error('Error:', error);
      // Handle the error as needed
    }
  };

  const selectOption = (selectorId, value) => {
    const selector = document.getElementById(selectorId);
    if (selector) {
      selector.value = value;
    } else {
      console.error(`Selector with ID ${selectorId} not found.`);
    }
  };

  const addNewOptionToSelector = (selectorId, id, company, street, city, state, zip_code) => {
    const selector = document.getElementById(selectorId);
    if (selector) {
      const newOption = document.createElement('option');
      newOption.value = id;
      newOption.text = [company, street, [city, [state, zip_code].join(' ')].join(', ')].join("\n");
      selector.appendChild(newOption);
    } else {
      console.error(`Selector with ID ${selectorId} not found.`);
    }
  };

  const errorMessage = (errors, field) => {
    if (errors[field]) {
      return (
        <p className="text-danger mt-1 mb-0">
          <i className="fas fa-times-circle mr-1"></i>
          {errors[field]}
        </p>
      )
    }
    
  }

  return (
    <div>
      <p className="mt-0 mb-0">
        <a className="btn btn-link pb-0" onClick={toggle}>Custom Address</a>
      </p>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Create New Address</ModalHeader>
        <Form onSubmit={handleSubmit} className={errors ? 'needs-validation' : ''}>
          <ModalBody>
            {responseError && <p className="text-danger">{responseError}</p>}
            <FormGroup>
              <Label for="company">Company</Label>
              <Input name="company" value={formData.company} onChange={handleChange} className={errors.company ? 'is-invalid' : ''} />
              {errorMessage(errors, 'company')}
            </FormGroup>
            <FormGroup>
              <Label for="street">Street</Label>
              <Input name="street" value={formData.street} onChange={handleChange} className={errors.street ? 'is-invalid' : ''}/>
              {errorMessage(errors, 'street')}
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input name="city" value={formData.city} onChange={handleChange} className={errors.city ? 'is-invalid' : ''}/>
              {errorMessage(errors, 'city')}
            </FormGroup>
            <FormGroup>
              <Label for="state">State</Label>
              <Input name="state" value={formData.state} onChange={handleChange} className={errors.state ? 'is-invalid' : ''}/>
              {errorMessage(errors, 'state')}
            </FormGroup>
            <FormGroup>
              <Label for="zipCode">Zip Code</Label>
              <Input name="zip_code" value={formData.zip_code} onChange={handleChange} className={errors.zip_code ? 'is-invalid' : ''}/>
              {errorMessage(errors, 'zip_code')}
            </FormGroup>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input name="country" value={formData.country} onChange={handleChange} className={errors.country ? 'is-invalid' : ''}/>
              {errorMessage(errors, 'country')}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Create</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateAddressModal;
