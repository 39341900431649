import React, { useState, useEffect } from 'react';
import {  FormGroup, Label, Row, Col, Input } from 'reactstrap';
import Select from 'react-select';

const AddressField = (props) => {
  const {
    type,
    options,
    selectedAddressId,
    defaultAddress,
    newRecord,
  } = props;

  const [isCustomAddress, setIsCustomAddress] = useState(false);
  const [address, setAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState({});
  const [company, setCompany] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  const addressOptions = options.map((record) => ({
    value: record.id,
    label: record.address,
  }));

  const parseAddress = (formattedAddress) => {
    const [company, street, cityStateZip] = formattedAddress.split('\n');
    const [city, stateZip] = cityStateZip.split(', ');
    const [state, zipCode] = stateZip.split(' ');
  
    return { company, street, city, state, zipCode };
  };

  const handleAddressChange = (name, value) => {
    const functionName = `set${name}`;
    if (typeof eval(functionName) === 'function') {
      eval(functionName)(value);
    }

    // Use the updated state values to create the formatted address
    const updatedCompany = name === 'Company' ? value : company;
    const updatedStreet = name === 'Street' ? value : street;
    const updatedCity = name === 'City' ? value : city;
    const updatedState = name === 'State' ? value : state;
    const updatedZipCode = name === 'ZipCode' ? value : zipCode;

    const formattedAddress = [updatedCompany, updatedStreet, [updatedCity, [updatedState, updatedZipCode].join(' ')].join(', ')].join('\n');
    setAddress(formattedAddress);
  }

  useEffect(() => {
    if (isCustomAddress) {
      setSelectedAddress({ value: '' });
    }
  }, [isCustomAddress]);

  useEffect(() => {
    if (!selectedAddress || isCustomAddress) return;

    setAddress(selectedAddress.label);
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedAddressId) {
      setSelectedAddress(addressOptions.find((option) => option.value === selectedAddressId));
    } else {
      if (!newRecord) setIsCustomAddress(true);
    }
    if (defaultAddress) {
      setAddress(defaultAddress);
      const data = parseAddress(defaultAddress);
      setCompany(data.company);
      setStreet(data.street);
      setCity(data.city);
      setState(data.state);
      setZipCode(data.zipCode);

    }
  }, []);

  return (
    <>
      {!isCustomAddress && 
        <Select 
          name={`pallet_label_${type}_address`}
          options={addressOptions}
          onChange={setSelectedAddress}
          value={selectedAddress}
        />
      }
      {isCustomAddress && <>
        <Row>
          <Col md={3}>
            <FormGroup>
            <Label for="company">
              Company
            </Label>
            <Input
              id="company"
              name="company"
              value={company}
              onChange={(e) => handleAddressChange('Company', e.target.value)}
            />
          </FormGroup>
          </Col>
          <Col md={9}>
            <FormGroup>
            <Label for="street">
              Street Address
            </Label>
            <Input
              id="street"
              name="street"
              value={street}
              onChange={(e) => handleAddressChange('Street', e.target.value)}
            />
          </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="city">
                City
              </Label>
              <Input
                id="city"
                name="city"
                value={city}
                onChange={(e) => handleAddressChange('City', e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="state">
                State
              </Label>
              <Input
                id="state"
                name="state"
                value={state}
                onChange={(e) => handleAddressChange('State', e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="zip">
                Zip
              </Label>
              <Input
                id="zip"
                name="zip"
                value={zipCode}
                onChange={(e) =>  handleAddressChange('ZipCode', e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        </>
      }
      <Input type="hidden" id={`pallet_label_${type}_address`} name={`pallet_label[${type}_address]`} value={address} />
      <Input type="hidden" id={`pallet_label_${type}_id`} name={`pallet_label[${type}_id]`} value={selectedAddress.value} />
      <FormGroup check className={isCustomAddress ? '' : 'mt-5'}>
        <Input type="checkbox" id={`customAddress_${type}`} checked={isCustomAddress} value={isCustomAddress} onChange={() => {setIsCustomAddress(!isCustomAddress)}} />
        <Label for={`customAddress_${type}`} check>Custom Address</Label>
      </FormGroup>
    </>
  )
}

export default AddressField;
