import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

// we need to make this component reusable and pass the items with links
const DropdownButton = ({ btnText, btnClass, links }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const download = (link) => {
    window.open(link, '_blank');
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up">
      <DropdownToggle className={btnClass}>
          <span className="ml-1">
            {btnText} <i className="mdi mdi-chevron-down"></i>
          </span>
        </DropdownToggle>
      <DropdownMenu>
        {links.map((link, index) => (
          <DropdownItem key={index} onClick={() => download(link.url)}>
            {link.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropdownButton;
