const hideThirdPartyBillingAddress = () => {
  const form = document.getElementById('shipment-from');
  const address = form.querySelector('#bill_of_lading_bill_to');
  const toggle = form.querySelector('#bill_of_lading_third_party');

  address.disabled = !toggle.checked
  toggle.addEventListener('change', (event) => {
    address.value = ''
    address.disabled = !event.target.checked
  });
}

const countTotalsQty = (totalName, name) => {
  const totalInput = document.getElementsByName(`bill_of_lading[${totalName}]`)
  const elements = document.querySelectorAll(`input[name^='bill_of_lading[${name}_']`);

  let total = 0;
  elements.forEach(element => {
    const qty = parseInt(element.value);
    if (!isNaN(qty)) {
      total += qty;
    }
  });

  if (totalInput) {
    totalInput[0].value = total;
  }
}

const trigerCountTotals = (totalInputs, input) => {
  for (let name of totalInputs) {
    const rowName = name.replace('total_', '');

    if (input.name.includes(`bill_of_lading[${rowName}`)) {
      countTotalsQty(name, rowName)
    }
  }
}

const recalculateTotals = (totalInputs, blockName) => {
  const block = document.getElementById(blockName);
  const inputs = block.getElementsByTagName('input')

  for (let input of inputs) {
    input.oninput = function () {
      input.parentElement.parentElement.dataset.emptyRow = false;
      trigerCountTotals(totalInputs, input);
    };
  }
};

const totalProductNames = [
  'total_handling_unit_qty',
  'total_package_qty',
  'total_weight',
];

const totalCustomerOrderNames = [
  'customer_order_total_pkgs',
  'customer_order_total_weight',
];

const handleCarrierChange = () => {
  const form = document.getElementById('shipment-from');
  const scasSelect = form.querySelector('#bill_of_lading_scac'); 
  const carrierSelect = form.querySelector('#bill_of_lading_carrier_name');
  // const carrierId = form.querySelector('#bill_of_lading_carrier_id');
  carrierSelect.addEventListener('change', (event) => {
    const scac_value = event.target.selectedOptions[0].dataset.scac;
    if (scac_value) {
      scasSelect.value = scac_value;
    } else {
      scasSelect.value = '';
    }
  })
  // carrierId.value = carrierSelect.selectedOptions[0].dataset.id;
}

const ShipmentForm = {
  init: () => {
    const form = document.getElementById('shipment-from');

    if (form) {
      hideThirdPartyBillingAddress();
      recalculateTotals(totalProductNames, 'shipment-info-cart');
      recalculateTotals(totalCustomerOrderNames, 'order-information');
      handleCarrierChange();
    }
  }
}

export default ShipmentForm;