import React, { useState } from "react";
import Select from "react-select";


const SelectField = ({ val, name, className, options }) => {
  const [value, setValue] = useState(val ? new Date(val) : null);

  return (
    <Select 
      options={options}
      onChange={(option) => setValue(option.value)}
    />
  );
};

export default SelectField;
